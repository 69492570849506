import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import cv from "../about/profile/cv.pdf"

function Profile({ data }) {
  return (
    <div className="side">
      <a
        class="button"
        href={cv}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download CV
      </a>
      <p>
        For those wishing to view and share a more traditional format of my
        professional history
      </p>
      <Img
        className="availPic"
        fluid={data.availability.childImageSharp.fluid}
        alt="Busy"
      />
      <p>Occupied with current projects, but open to oppourtunities</p>
    </div>
  )
}

const availabilityPic = () => (
  <StaticQuery
    query={graphql`
      {
        availability: file(
          relativePath: { eq: "assets/images/availability.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Profile data={data} />}
  />
)

export default availabilityPic;

