import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"
import Profile from "../../components/profile"

const PageContent = () => (
  <div className="pageContent">
    <h2>Professional Profile</h2>
    <p>
      Software engineer with experience in management analysis, design,
      development, and researching products
    </p>
    <p>
      Making contributions from startups and small businesses, to governments
      including NYC.gov, to large companies like Lockheed Martin, and Motorola.
    </p>

    <p>
      Received a diploma in Software Engineering from Drexel University, with
      additional learning from General Assembly.
    </p>
  </div>
)

export default () => {
  return (
    <div className="about">
      <div className="contentArea">
        <Profile />
        <PageContent />
      </div>
      <TileList />
      <SubnavList />
    </div>
  )
}
